import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout/layout-single'
import ProcessBlogs from '../components/datacontent'
import SEO from '../components/SEO/seo'
import List from '../components/Blocklist/list'
import Bloglist from '../components/Bloglist/list'
import Header from '../components/Header/header'

class SecondPage extends React.Component {
  render () {
    const data = this.props.data
    const allItems = ProcessBlogs(data.cases.edges, data.projectcases.edges)
    const projectsTag = ProcessBlogs(data.tagprojects.edges, [], allItems)

    let postsElementsFirst, postsElementsRest
    if (allItems.length > 2) {
      postsElementsFirst = allItems.slice(0, 2)
      postsElementsRest = allItems.slice(2)
    } else {
      postsElementsFirst = allItems
    }

    return (
      <Layout>
        <>
          <SEO title="UX Projects" imagefile="all" location={this.props.location} />
          <Header
            location={this.props.location}
            fluid={data.file.childImageSharp.fluid}
            alt="UX and design cases"
            title="UX and design cases"
          />
        </>
        <>
          <h1>UX and design cases</h1>
          <p>You-et likes to guide clients and their projects to create value with and through Artificial Intelligence and Design. This diverse background could give an innovative boost to your teams and projects! Please, take a look at some of the projects.</p>
          <ul className="paragraph-buttons">
            <li><a className="paragraph-button__button-active">Design cases</a></li>
            <li><Link to="/design-process">The design process</Link></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
          <Bloglist posts={postsElementsFirst} size="big" />
          {postsElementsRest && <Bloglist posts={postsElementsRest.slice(0, 5)} />}
          <h2>Design deliverables</h2>
          <List items={projectsTag} size="medium" />
          <ul className="paragraph-buttons">
            <li><a className="paragraph-button__button-active">Design cases</a></li>
            <li><Link to="/design-process">The design process</Link></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
        </>
      </Layout>
    )
  }
}

export default SecondPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tagprojects: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {tags: {in: [27]}},
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    projectcases: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {mainType: {in: ["case", "interactive"]}},
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    cases: allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {mainType: {in: ["case", "interactive"]}}}
    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
    readmore: allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {mainType: {in: ["blog"]}}},
      limit: 3
    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
    file(relativePath: { eq: "overview/patrick-perkins-ETRPjvb0KM0-unsplash.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
